html {
	height: 100%;
	font-size: 62.5%;
}
body {
	height: 100%;
	position: relative;
	overflow-x: hidden;
	font-size: tovw(18);
	color: #fff;
	font-family: $goldman;
	background-color: #000;
	&.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
		height: 100% !important;
	}
}
img {
	max-width: 100% !important;
}
#root {
	position: relative;
	min-height: 100%;
}
button {
	outline: none !important;
}
a {
	color: #fff;
	transition: color 0.25s ease;
	outline: none !important;
	&:hover {
		color: #fff;
		text-decoration: none;
	}
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-left: 1rem;
	padding-right: 1rem;
}
.container {
	max-width: 100% !important;
	padding-left: 1rem;
	padding-right: 1rem;
	&--content {
		max-width: tovw(1400) !important;
	}
}
.row {
	margin-left: -1rem;
	margin-right: -1rem;
}
#main-body {
	position: relative;
}
.main-content {
	position: relative;
	padding-top: tovw(75);
}
#header {
	z-index: 9;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
.top-bar {
	height: tovw(75);
	line-height: tovw(75);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: wrap;
	background: url(/images/bg-header.png) no-repeat;
	background-size: 100% 100%;
	&__logo {
		width: 21%;
		height: 100%;
		text-align: center;
		position: relative;
		img {
			width: tovw(285);
		}
	}
	&__menu {
		width: 48.3%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__user {
		width: 30.7%;
		height: 100%;
		// background: url(/images/bg-user.png) repeat-x;
		// background-size: 100% 100%;
	}
}
.menu-link {
	display: inline-block;
	height: tovw(75);
	line-height: tovw(75);
	width: 25%;
	font-size: tovw(24);
	text-align: center;
	// border-right: 1px solid #000149;
	background: transparent;
	text-transform: uppercase;
	font-family: $roboto;
	color: #000b21;
	transition: background 0.5s ease, border-bottom 0.5s ease;
	margin-top: tovw(-3);
	&.active,
	&:hover {
		color: #00004b;
		background: url(/images/bg-menu.png) no-repeat;
		background-size: 100% 100%;
	}
}
.btn-header {
	display: inline-block;
	width: tovw(220);
	height: tovw(63);
	line-height: tovw(58);
	// background: url(/images/btn-header.png) no-repeat;
	// background-size: 100% 100%;
	font-size: tovw(22);
	text-transform: uppercase;
	// text-shadow: 0px tovw(3) 0px rgba(140, 13, 10, 0.4);
	text-align: center;
	transition: transform 0.5s ease;
	font-family: $roboto;
	color: #fff4bb;
	font-style: italic;
	font-weight: 500;
	&--login {
		margin-left: tovw(390);
	}
	&--logout {
		padding-left: tovw(50);
	}
	&:hover {
		transform: scale(0.9);
		color: #fff4bb;
	}
}
.user-info {
	color: #000e26 !important;
	font-size: tovw(22);
	font-family: $roboto;
	display: inline-block;
	width: tovw(355);
	text-align: center;
	strong {
		color: #000e26;
		font-weight: normal;
	}
}
.nickname {
	display: inline-block;
	max-width: 13vw;
	overflow: hidden;
	vertical-align: middle;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-top: tovw(-2);
}
.no-margin {
	margin: 0 !important;
}
.disable {
	pointer-events: none;
	filter: grayscale(1);
}
.wrap-video {
	width: 100%;
	position: relative;
	z-index: 1;
	&.no-video {
		video {
			display: none;
		}
		img {
			display: block;
			@include min-screen(1200px) {
				display: block;
			}
		}
	}
	video {
		width: 100%;
		// display: none;
		display: block;
		@include max-screen(1200px) {
			display: none;
		}
	}
	img {
		width: 100%;
		// display: block;
		@include min-screen(1200px) {
			display: none;
		}
	}
	&--accumulated {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		user-select: none;
		pointer-events: none;
		video {
			height: 100%;
			object-fit: cover;
		}
		img {
			width: 100%;
			display: block;
			height: 100%;
			// object-fit: cover;
			@include min-screen(1200px) {
				display: none;
			}
		}
	}
}
.section {
	position: relative;
	&--1 {
		min-height: tovw(1080);
		z-index: 5;
	}
	&--2 {
		min-height: tovw(1260);
		z-index: 4;
	}
}
.spin {
	padding-top: tovw(5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	&__wrap {
		width: tovw(1800);
		margin: 0 auto;
		position: relative;
	}
	&__foosball-table {
		position: relative;
		width: tovw(1250);
		.img-foosball-table {
			width: 100%;
		}
	}
	&__medal {
		position: absolute;
		top: tovw(62);
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		margin-left: tovw(-4);
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: tovw(400);
		perspective: 100px;
		&--item {
			width: 20%;
			text-align: center;
			position: relative;
			animation: heartBeatLow 1.5s linear infinite;
			img {
				width: tovw(63);
				position: relative;
				z-index: 2;
			}
			span {
				position: absolute;
				top: tovw(-35);
				left: 50%;
				transform: translateX(-50%);
				display: inline-block;
				text-align: center;
				background: url(/images/point-spin.png) no-repeat;
				background-size: 100% 100%;
				// background-color: #32ff40;
				// border-radius: tovw(10);
				width: tovw(66);
				height: tovw(28);
				line-height: tovw(21);
				font-size: tovw(16);
				font-family: $fvf;
				text-shadow: 1px 1px 0 #090027, 1px -1px 0 #090027, -1px 1px 0 #090027,
					-1px -1px 0 #090027, 1px 0px 0 #090027, 0px 1px 0 #090027,
					-1px 0px 0 #090027, 0px -1px 0 #090027;
			}
			&::before {
				content: '';
				width: tovw(72);
				height: tovw(72);
				background: url(/images/bg-medal-spin.png) no-repeat;
				background-size: 100% 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
			}
			&.active {
				&::before {
					background: url(/images/bg-medal-spin-active.png) no-repeat;
					background-size: 100% 100%;
					filter: drop-shadow(0 0 tovw(6) #fff) brightness(2.2);
				}
			}
		}
	}
	&__players {
		> div {
			position: absolute;
			z-index: 1;
			perspective: 100px;
		}
		img {
			height: tovw(156);
			animation: rotateVertical 1s linear infinite;
		}
		&--1 {
			bottom: tovw(180);
			left: 50%;
			transform: translateX(-50%);
			margin-left: tovw(-20);
			img {
				height: tovw(189);
			}
		}
		&--2 {
			bottom: tovw(309);
			left: tovw(450);
			animation-delay: 0.4s;
		}
		&--3 {
			bottom: tovw(309);
			left: tovw(740);
			animation-delay: 0.4s;
		}
		&--4 {
			bottom: tovw(410);
			left: tovw(330);
			animation-delay: 0.6s;
		}
		&--5 {
			bottom: tovw(410);
			left: tovw(585);
			animation-delay: 0.6s;
		}
		&--6 {
			bottom: tovw(410);
			left: tovw(835);
			animation-delay: 0.6s;
		}
		&--7 {
			bottom: tovw(505);
			left: tovw(480);
			img {
				height: tovw(146);
				animation-delay: 0.7s;
			}
		}
		&--8 {
			bottom: tovw(505);
			left: tovw(720);
			img {
				height: tovw(146);
				animation-delay: 0.7s;
			}
		}
		&--9 {
			bottom: tovw(594);
			left: tovw(635);
			img {
				height: tovw(140);
				animation-delay: 0.8s;
			}
		}
	}
	&__ball {
		position: absolute;
		bottom: tovw(210);
		left: tovw(645);
		img {
			width: tovw(45);
			position: relative;
			z-index: 1;
			// animation: type3medal3 1.5s linear 1;
			// animation-delay: 2s;
			animation-fill-mode: forwards;
		}
		&::before {
			content: '';
			position: absolute;
			bottom: tovw(-5);
			left: 50%;
			transform: translateX(-50%);
			width: tovw(33);
			height: tovw(16);
			background: url(/images/shadow-ball.png) no-repeat;
			background-size: 100% 100%;
		}
	}
	&__actions {
		position: absolute;
		bottom: tovw(105);
		left: tovw(100);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 4;
		a {
			display: inline-block;
			width: tovw(245);
			height: tovw(66);
			line-height: tovw(66);
			font-size: tovw(20);
			font-family: $roboto;
			margin-left: tovw(10);
			text-align: center;
			transition: all 0.5s ease;
			position: relative;
			span {
				font-size: tovw(26);
				// filter: drop-shadow(0 3px tovw(3) crimson);
			}
		}
		.btn-special {
			display: inline-block;
			position: absolute;
			top: tovw(-13);
			left: tovw(20);
			width: tovw(85);
			height: tovw(35);
			line-height: tovw(32);
			background: url(/images/polygon-red.png) no-repeat;
			background-size: 100% 100%;
			font-family: $roboto;
			font-weight: bold;
			font-size: tovw(20);
			color: #fff281;
			text-shadow: none;
		}
		&--1 {
			background: url(/images/btn-free.png) no-repeat;
			background-size: 100% 100%;
			text-transform: uppercase;
			color: #fff;
			// text-shadow: 1px 1px 0 #084085, 1px -1px 0 #084085, -1px 1px 0 #084085, -1px -1px 0 #084085, 1px 0px 0 #084085, 0px 1px 0 #084085, -1px 0px 0 #084085, 0px -1px 0 #084085;

			&:hover,
			&.active {
				background: url(/images/btn-free-active.png) no-repeat;
				background-size: 100% 100%;
				// color: #fff;
				// text-shadow: 1px 1px 0 #5a94e9, 1px -1px 0 #5a94e9, -1px 1px 0 #5a94e9, -1px -1px 0 #5a94e9, 1px 0px 0 #5a94e9, 0px 1px 0 #5a94e9, -1px 0px 0 #5a94e9, 0px -1px 0 #5a94e9;
			}
		}
		&--2 {
			background: url(/images/btn-play1.png) no-repeat;
			background-size: 100% 100%;
			color: #fff;
			// text-shadow: 1px 1px 0 #f1c049, 1px -1px 0 #f1c049, -1px 1px 0 #f1c049, -1px -1px 0 #f1c049, 1px 0px 0 #f1c049, 0px 1px 0 #f1c049, -1px 0px 0 #f1c049, 0px -1px 0 #f1c049;
			&:hover,
			&.active {
				background: url(/images/btn-play1-active.png) no-repeat;
				background-size: 100% 100%;
				// color: #fff;
				// text-shadow: 1px 1px 0 #990c03, 1px -1px 0 #990c03, -1px 1px 0 #990c03, -1px -1px 0 #990c03, 1px 0px 0 #990c03, 0px 1px 0 #990c03, -1px 0px 0 #990c03, 0px -1px 0 #990c03;
			}
		}
		&--3 {
			background: url(/images/btn-play10.png) no-repeat;
			background-size: 100% 100%;
			color: #fff;
			// text-shadow: 1px 1px 0 #900b0b, 1px -1px 0 #900b0b, -1px 1px 0 #900b0b, -1px -1px 0 #900b0b, 1px 0px 0 #900b0b, 0px 1px 0 #900b0b, -1px 0px 0 #900b0b, 0px -1px 0 #900b0b;
			&:hover,
			&.active {
				background: url(/images/btn-play10-active.png) no-repeat;
				background-size: 100% 100%;
				// color: #f8e68e;
				// text-shadow: 1px 1px 0 #b8161d, 1px -1px 0 #b8161d, -1px 1px 0 #b8161d, -1px -1px 0 #b8161d, 1px 0px 0 #b8161d, 0px 1px 0 #b8161d, -1px 0px 0 #b8161d, 0px -1px 0 #b8161d;
			}
			.btn-special {
				background: url(/images/polygon-yellow.png) no-repeat;
				background-size: 100% 100%;
				color: #fe0e0e;
			}
		}
		&--4 {
			background: url(/images/btn-play50.png) no-repeat;
			background-size: 100% 100%;
			color: #fff;
			// text-shadow: 1px 1px 0 #0b4580, 1px -1px 0 #0b4580, -1px 1px 0 #0b4580, -1px -1px 0 #0b4580, 1px 0px 0 #0b4580, 0px 1px 0 #0b4580, -1px 0px 0 #0b4580, 0px -1px 0 #0b4580;
			&:hover,
			&.active {
				background: url(/images/btn-play50-active.png) no-repeat;
				background-size: 100% 100%;
				// color: #fff;
				// text-shadow: 1px 1px 0 #0c55a1, 1px -1px 0 #0c55a1, -1px 1px 0 #0c55a1, -1px -1px 0 #0c55a1, 1px 0px 0 #0c55a1, 0px 1px 0 #0c55a1, -1px 0px 0 #0c55a1, 0px -1px 0 #0c55a1;
			}
		}
	}
	&__play-type {
		position: absolute;
		bottom: tovw(175);
		left: tovw(115);
		font-size: tovw(20);
		font-family: $roboto;
		text-shadow: 1px 1px 0 #001eff, 1px -1px 0 #001eff, -1px 1px 0 #001eff,
			-1px -1px 0 #001eff, 1px 0px 0 #001eff, 0px 1px 0 #001eff,
			-1px 0px 0 #001eff, 0px -1px 0 #001eff;
		p {
			text-align: center;
			filter: drop-shadow(0 0 tovw(7) #440000);
			text-shadow: 1px 1px 0 #ff0000, 1px -1px 0 #ff0000, -1px 1px 0 #ff0000,
				-1px -1px 0 #ff0000, 1px 0px 0 #ff0000, 0px 1px 0 #ff0000,
				-1px 0px 0 #ff0000, 0px -1px 0 #ff0000;
		}
		div {
			width: tovw(284);
			height: tovw(47);
			background: url(/images/bg-play-type.png) no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			position: relative;
			color: #000000 !important;
			text-shadow: none;
			a {
				color: #000000 !important;
			}
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				right: tovw(83);
				transform: translateY(-50%);
				width: 2px;
				height: 70%;
				background-color: #333c44;
			}
		}
		img {
			width: tovw(24);
			margin-right: tovw(5);
		}
	}
	&__top {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: tovw(5);
		margin-left: tovw(96);
	}
	&__congrat {
		margin-right: tovw(15);
		> div {
			width: tovw(350);
			height: tovw(64);
			background: url(/images/bg-congrat.png) no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			padding: 0 tovw(6);
			margin-bottom: tovw(10);
			// filter: drop-shadow(0 0 0.3rem #ff8f00);
			&:last-child {
				margin-bottom: 0;
			}
		}
		&--gift {
			width: tovw(46);
			height: tovw(46);
			border-radius: 50%;
			// background-color: #63020a;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: tovw(10);
			position: relative;
			img {
				width: tovw(27);
				animation: tada 1s linear infinite;
			}
		}
		&--content {
			font-size: tovw(14);
			color: #000d2c;
			font-family: $roboto;
			width: 85%;
			line-height: 1.2;
			position: relative;
		}
		.fifa {
			font-size: tovw(30);
		}
	}
	&__jackpot {
		position: relative;
		margin-right: tovw(15);
		// filter: drop-shadow(0 0 0.3rem #ff8f00);
		> img {
			width: tovw(345);
		}
		&--text {
			position: absolute;
			top: tovw(30);
			left: 50%;
			transform: translateX(-50%);
			img {
				width: tovw(220);
			}
		}
		&--total {
			font-size: tovw(28);
			text-transform: uppercase;
			color: #fef6c4;
			font-family: $roboto;
			position: absolute;
			bottom: tovw(21);
			left: tovw(100);
			width: tovw(220);
			text-align: center;
		}
		&--type {
			position: absolute;
			bottom: tovw(26);
			left: tovw(30);
			img {
				width: tovw(57);
			}
		}
	}
	&__info-money {
		padding: tovw(7) tovw(25);
		background: url(/images/bg-info.png) no-repeat;
		background-size: 100% 100%;
		width: tovw(270);
		// filter: drop-shadow(0 0 0.3rem #ff8f00);
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				font-size: tovw(18);
				color: #001433;
				font-family: $roboto;
				padding-bottom: tovw(7);
				border-bottom: 1px solid #77779f;
				margin-bottom: tovw(7);
				text-shadow: 0px tovw(3) 0px rgba(17, 55, 81, 0.004);
				img {
					width: tovw(18);
					margin-top: tovw(-8);
				}
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: 0;
				}
			}
		}
	}
	&__rewards {
		position: absolute;
		top: 0;
		right: tovw(40);
	}
	&__list {
		width: tovw(600);
		background: url(/images/bg-list-reward.png) no-repeat;
		background-size: 100% 100%;
		padding-top: tovw(80);
		padding-bottom: tovw(10);
		position: relative;
		z-index: 2;
		margin-top: tovw(-7);
		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	top: tovw(25);
		// 	right: tovw(-13);
		// 	width: tovw(29);
		// 	height: tovw(305);
		// 	background: url(/images/decor3.png) no-repeat;
		// 	background-size: 100% 100%;
		// }
		h3 {
			font-size: tovw(22);
			color: #144c54;
			text-align: center;
			text-shadow: 1px 1px 0 #79a596, 1px -1px 0 #79a596, -1px 1px 0 #79a596,
				-1px -1px 0 #79a596, 1px 0px 0 #79a596, 0px 1px 0 #79a596,
				-1px 0px 0 #79a596, 0px -1px 0 #79a596;
			font-family: $fvf;
			padding-top: tovw(6);
			margin-bottom: 0;
		}
	}
	&__reward-level {
		&--1 {
			padding: 0 tovw(20);
		}
		&--2 {
			padding: tovw(10) 0;
			// background-color: #284178;
			// background: url(/images/bg-line2.png) no-repeat;
			// background-size: 100% 100%;
			margin: 0 tovw(14) 0 tovw(14);
		}
		&--3 {
			padding: 0 tovw(0);
			margin: 0 tovw(15) 0 tovw(15);
			position: relative;
			z-index: 7;
		}
	}
	&__line {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.medal-random {
			width: tovw(36);
		}
		&--1 {
			margin-bottom: tovw(6);
		}
		&--2 {
			border-bottom: 1px solid #ffe992;
			padding-bottom: tovw(5);
			margin-bottom: tovw(5);
			&:last-child {
				border: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
		&--3 {
			padding: tovw(10) 0;
			align-items: flex-start;
		}
	}
	&__total-medal {
		display: flex;
		align-items: center;
		font-family: $roboto;
		text-shadow: 0px tovw(3) 0px rgba(0, 53, 67, 0.75);
		font-size: tovw(22);
		img {
			width: tovw(46);
			margin-right: tovw(8);
		}
		&--1 {
			width: tovw(110);
		}
		&--2 {
			width: tovw(172);
			justify-content: center;
			> div {
				&:first-child {
					margin-right: tovw(8);
				}
			}
		}
		&--3 {
			width: tovw(172);
			justify-content: center;
			position: relative;
			span {
				display: inline-block;
				padding: 0 tovw(3) 0 tovw(4);
			}
			p {
				position: absolute;
				bottom: tovw(-30);
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				font-size: tovw(16);
				margin-bottom: 0;
				text-align: center;
			}
		}
	}
	&__wrap-rewards {
		display: flex;
		align-items: center;
		justify-content: space-around;
		// border-radius: tovw(8);
		font-family: $roboto;
		font-size: tovw(18);
		color: #062739;
		img {
			height: tovw(40);
			&.active {
				// animation: won 1s linear infinite;
				filter: drop-shadow(0 0 tovw(6) red) brightness(1.2);
			}
		}
		&--1 {
			width: tovw(360);
			// padding: tovw(5) 0;
			padding-bottom: tovw(5);
			background-color: #ededed;
			border-top: tovw(3) solid #9ea0b9;
		}
		&--2 {
			width: tovw(305);
			padding: tovw(2) 0;
			background-color: #dadfe1;
			border: 1px solid #dadfe1;
		}
		&--3 {
			width: tovw(305);
			padding: tovw(2) 0;
			background-color: #dadfe1;
			border-top: tovw(3) solid #9ea0b9;
			flex-wrap: wrap;
			justify-content: flex-start;
			> div {
				width: 16.666%;
				text-align: center;
				margin-bottom: tovw(5);
			}
		}
	}
	&__point {
		width: tovw(65);
		height: tovw(50);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: tovw(20);
		color: #062739;
		font-family: $roboto;
		margin-left: tovw(10);
		background: url(/images/point-default.png) no-repeat;
		background-size: 100% 100%;
		// background-color: #ff8a00;
		padding-top: tovw(3);
		text-align: center;
		line-height: 1;
		// &--1 {
		//   background: url(/images/point-lv1.png) no-repeat;
		//   background-size: 100% 100%;
		//   color: #091a29;
		// }
	}
}
.title {
	margin-top: tovw(-10);
	margin-bottom: tovw(10);
	text-align: center;
	img {
		width: tovw(480);
		animation: move-shaking 1.2s linear infinite;
	}
}
.accumulated {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
	&__wrap {
		width: tovw(1500);
		height: 100%;
		margin: 0 auto;
		padding-top: tovw(70);
	}
	&__title {
		text-align: center;
		margin-bottom: tovw(5);
		img {
			width: tovw(442);
			margin-bottom: tovw(10);
		}
		p {
			font-size: tovw(20);
			font-family: $roboto;
			margin-bottom: 0;
			color: #fff;
			span {
				display: inline-block;
				width: tovw(384);
				height: tovw(58);
				line-height: tovw(62);
				background: url('/images/bg-total-point.png') no-repeat;
				background-size: 100% 100%;
				color: #ffff;
				font-family: $roboto;
				padding-left: tovw(55);
				font-size: tovw(18);
				font-weight: 500;
				margin-bottom: tovw(15);
			}
		}
	}
	&__gifts {
		width: tovw(1322);
		height: tovw(790);
		margin: 0 auto;
		padding: tovw(0) 0 0;

		&--row {
			display: flex;
			justify-content: center;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				bottom: tovw(-45);
				left: 0;
				right: 0;
				margin: 0 auto;
				width: tovw(1300);
				height: tovw(207);
				background: url('/images/decor-gifts.png') 0 0/100% 100% no-repeat;
			}
		}

		&--row ~ &--row {
			margin-top: tovw(90);
		}
	}
}
.gift-item {
	$self: '.gift-item';

	margin: 0 tovw(15);
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: tovw(50);

	&__note {
		position: absolute;
		top: tovw(39);
		right: tovw(-27);
		z-index: 1;
	}

	&__image {
		perspective: 100px;
		img {
			height: tovw(134);
			animation: rotateHorizon 2s linear infinite;
		}
	}

	&__times {
		font-size: tovw(16);
		width: tovw(103);
		height: tovw(37);
		line-height: tovw(41);
		margin: 0 auto tovw(15);
		text-transform: uppercase;
		font-family: $hcks;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&__dialog {
		width: tovw(204);
		height: tovw(46);
		font-size: tovw(14);
		font-family: $roboto;
		line-height: 1.2em;
		text-transform: uppercase;
		padding-top: tovw(13);
		position: absolute;
		top: calc(100% + #{tovw(10)});
		left: calc(50% - #{tovw(204/2)});
		color: #ffe992 !important;
		background: url('/images/bg-dialog.png') 0 0/100% 100% no-repeat;

		&.small {
			width: tovw(204, 0.7);
			height: tovw(46, 0.7);
			padding-top: tovw(13);
			left: calc(50% - #{tovw(204/2, 0.7)});
		}

		&.long {
			width: tovw(244);
			height: tovw(46);
			padding-top: tovw(8);
			left: calc(50% - #{tovw(244/2)});
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: tovw(18);
			color: #ffe992 !important;
			text-transform: none;
			background: url('/images/bg-dialog-long.png') 0 0/100% 100% no-repeat;
		}
	}

	&__single {
		&.claimed {
			#{$self}__image {
				position: relative;

				img {
					filter: grayscale(50%) brightness(50%);
				}

				&:before {
					content: '';
					width: tovw(91);
					height: tovw(92);
					background: url(https://cdn-webth.garenanow.com/webth/cdn/cdn_fo4team/tacatacass1/assets/icon-received.png)
						0 0/100% 100% no-repeat;
					position: absolute;
					top: calc(50% - #{tovw(92/2)});
					left: calc(50% - #{tovw(91/2)});
					z-index: 1;
					user-select: none;
					pointer-events: none;
				}
			}
		}
	}

	&__single ~ &__single {
		margin-left: tovw(21);
	}

	&[data-theme='cyan'] {
		#{$self}__times {
			font-family: $roboto;
			color: #001332 !important;
			font-weight: 500;
			background: url('/images/btn-cyan.png') 0 0/100% 100% no-repeat;

			&.is-large {
				width: tovw(244);
				height: tovw(37);
				font-weight: 500;
				color: #062739 !important;
				background: url('/images/btn-cyan-lg.png') 0 0/100% 100% no-repeat;
			}
		}
	}

	&.disabled {
		cursor: default !important;
	}
}
.table-history {
	width: 100%;
	tbody {
		tr {
			border-top: 1px solid #fff;
		}
	}
	tr {
		th,
		td {
			font-size: tovw(20);
			color: #fff;
			padding: tovw(15) 0;
			font-family: $roboto;
			font-weight: 300;
		}
		th {
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}
.modal-select {
	.popup-select {
		padding-bottom: tovw(30);
		&__desc {
			font-size: tovw(24);
			margin-bottom: tovw(30);
			color: #fff;
			font-family: $roboto;
		}
		&__items {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
		}
		&__item {
			width: 45%;
			&:hover {
				color: #fff;
			}
			img {
				height: tovw(268);
			}
			&--name {
				font-size: tovw(20);
				text-transform: uppercase;
				margin-bottom: tovw(20);
				color: #fff;
				font-family: $roboto;
			}
			.btn {
				margin-bottom: tovw(30);
			}
			&--note {
				font-size: tovw(18);
				text-transform: uppercase;
				color: #fff;
				border-top: 1px solid #e1a867;
				padding-top: tovw(8);
				font-family: $roboto;
			}
			&.claimed {
				.popup-select__item--image {
					img {
						filter: grayscale(50%) brightness(50%);
					}
				}
				.btn,
				.popup-select__item--name {
					filter: grayscale(50%) brightness(50%);
					pointer-events: none;
				}
			}
		}
		&__link {
			display: block;
			text-align: center;
			color: #000;
		}
	}
}
.modal-received-gift {
	.received-gift {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__medal {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: tovw(290);
			height: tovw(130);
			// background-color: #092a41;
			// border: 1px solid #2a7d8c;
			background: url(/images/bg-gift-medal.png) no-repeat;
			background-size: 100% 100%;
			padding: 0 tovw(25);
			img {
				width: tovw(72);
			}
		}
		&__item {
			width: tovw(130);
			height: tovw(130);
			// background-color: #092a41;
			// border: 1px solid #2a7d8c;
			background: url(/images/bg-gift-item.png) no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			img {
				height: tovw(105);
			}
			span {
				display: inline-block;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				margin-top: tovw(10);
				width: 150%;
				font-size: tovw(18);
				text-transform: uppercase;
				text-align: center;
				color: #fff;
				font-family: $roboto;
			}
		}
		.icon-equal,
		.icon-plus {
			width: tovw(27);
		}
	}
}
.point {
	width: tovw(130);
	height: tovw(130);
	background: url(/images/bg-point.png?v=1) no-repeat;
	background-size: 100% 100%;
	// background-color: #092a41;
	// border: 1px solid #2a7d8c;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	img {
		height: tovw(77);
		// display: none;
	}
	span {
		position: absolute;
		top: tovw(0);
		right: tovw(15);
		// transform: translate(-50%, -50%);
		font-size: tovw(32);
		color: #fff;
		font-family: $roboto;
		font-weight: 500;
		padding-top: tovw(7);
		// text-shadow: 1px 1px 0 #b9e7ca, 1px -1px 0 #b9e7ca, -1px 1px 0 #b9e7ca, -1px -1px 0 #b9e7ca, 1px 0px 0 #b9e7ca, 0px 1px 0 #b9e7ca, -1px 0px 0 #b9e7ca, 0px -1px 0 #b9e7ca;
	}
}
.received-gifts {
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 1px;
			height: 100%;
			background-color: #ffe486;
		}
	}
	&__items {
		width: 49%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// border-bottom: 1px solid #dea553;
		background: url(/images/bg-received-gifts.png?v=1) no-repeat;
		background-size: 100% 100%;
		margin-bottom: tovw(15);
		padding-bottom: tovw(15);
		padding: tovw(15);
	}
	&__item {
		display: flex;
		justify-content: space-around;
	}
	&__left {
		margin-right: tovw(10);
		position: relative;
		&::before {
			content: '';
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			right: tovw(-10);
			background-color: #ffe486;
		}
	}
	&__medal {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: tovw(295);
		height: tovw(90);
		background: url(/images/bg-gifts-medal.png?v=1) no-repeat;
		background-size: 100% 100%;
		padding: 0 tovw(35);
		margin-bottom: tovw(10);
		img {
			width: tovw(64);
		}
	}
	&__time {
		color: #fefefe;
		font-family: $roboto !important;
		font-weight: 300 !important;
		text-align: left !important;
		width: tovw(295);
		padding-left: tovw(10);
		&.yellow {
			color: #f2d668 !important;
		}
		&.red {
			color: #ad1502 !important;
		}
		&.reward-name {
			padding-left: 0;
			width: 90%;
			text-align: center !important;
		}
	}
	&__right {
		display: flex;
		justify-content: center;
		// align-items: center;
		// height: tovw(130);
		width: tovw(260);
		// background-color: #092a41;
		// border: 1px solid #2a7d8c;
		flex-wrap: wrap;
	}
	&__reward {
		text-align: center;
		width: tovw(125);
		height: tovw(116);
		background: url(/images/bg-gifts-item.png?v=1) no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: tovw(95);
		}
	}
	.point {
		width: 45%;
		height: auto;
		background: none;
		border: 0;
		background-color: transparent;
		margin-top: tovw(-10);
		span {
			top: tovw(0);
			right: tovw(5);
		}
	}
}
.menu-center {
	position: fixed;
	top: 50%;
	left: tovw(30);
	z-index: 5;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.btn-mission {
		margin-bottom: tovw(15);
		animation: ping 2s infinite;
		img {
			width: tovw(86);
		}
	}
	.btn-share {
		animation: heartBeat 2s infinite;
		img {
			width: tovw(75);
		}
	}
	&.ingame {
		left: inherit;
		right: 0.8525vw;
	}
}
.mission {
	p {
		font-size: tovw(32) !important;
		text-transform: uppercase;
		font-family: $roboto !important;
		font-weight: bold;
		margin-bottom: tovw(40);
		color: #fff;
	}
	span {
		font-size: tovw(28);
		display: block;
		font-weight: 300;
		color: #fff;
		font-style: italic;
	}
	&__btn {
		display: inline-block;
		width: tovw(255);
		height: tovw(55);
		line-height: tovw(48);
		text-transform: uppercase;
		color: #064c5d;
		font-family: $goldman !important;
		font-weight: bold;
		background: url(/images/mission.png) no-repeat;
		background-size: 100% 100%;
		text-shadow: 1px 1px 0 #b9e7ca, 1px -1px 0 #b9e7ca, -1px 1px 0 #b9e7ca,
			-1px -1px 0 #b9e7ca, 1px 0px 0 #b9e7ca, 0px 1px 0 #b9e7ca,
			-1px 0px 0 #b9e7ca, 0px -1px 0 #b9e7ca;
		text-align: center;
		margin: 0 auto;
		transition: all 0.3s ease;
		&:hover {
			text-shadow: 1px 1px 0 #064c5d, 1px -1px 0 #064c5d, -1px 1px 0 #064c5d,
				-1px -1px 0 #064c5d, 1px 0px 0 #064c5d, 0px 1px 0 #064c5d,
				-1px 0px 0 #064c5d, 0px -1px 0 #064c5d;
			color: #b9e7ca;
		}
	}
}
#footer {
	position: relative;
	background: #000;
	padding-top: 1.5vw;
	padding-bottom: 1.5vw;
	.container {
		width: tovw(1320);
	}
	.footer-logo {
		display: inline-block;
		width: tovw(226);
	}
	.age {
		display: inline-block;
		width: tovw(142);
	}
	.col:nth-child(2) {
		flex: 0 0 tovw(910);
		max-width: tovw(910);
	}
	p {
		font-size: tovw(11);
		text-transform: uppercase;
		color: #6a6a6a;
		text-align: justify;
		margin-bottom: 0;
	}
	img {
		width: 100%;
	}
}
.confetti {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;
	width: 1px !important;
	height: 1px !important;
	font-size: 0;
	background-color: transparent;
	cursor: default !important;
	span {
		display: inline-block;
		width: 1px;
		height: 1px;
	}
}
.confetti2 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 1px !important;
	height: 1px !important;
	font-size: 0;
	background-color: transparent;
	cursor: default !important;
	span {
		display: inline-block;
		width: 1px;
		height: 1px;
	}
}
#status {
	position: absolute;
	top: tovw(20);
	left: 0;
	width: 1;
	height: 1;
	z-index: -1;
}
.btn-his {
	height: tovw(50);
	display: inline-block;
	font-size: tovw(28);
	line-height: tovw(50);
	text-transform: uppercase;
	color: #fff !important;
	background: #062739;
	border: 1px solid #ff9000;
	margin-top: 1vw;
	margin-bottom: 1vw;
	font-weight: 500;
	font-family: $roboto;
	&:nth-child(1) {
		border-radius: tovw(9) 0 0 tovw(9);
	}
	&:nth-child(2) {
		border-radius: 0 tovw(9) tovw(9) 0;
	}
	&--active {
		background: #ff9000;
		color: #fff !important;
	}
}
.title-modal {
	height: tovw(65) !important;
	width: auto !important;
}
